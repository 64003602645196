import React from "react"
import { graphql } from "gatsby"
import { Button, Tabs, Divider } from 'antd';
import BlogPostChrome from "../../components/BlogPostChrome"
import Img from 'gatsby-image'
import { Row, Col } from 'antd';
import Layout from './../../components/Layouts';

const TabPane = Tabs.TabPane;

export const frontmatter = {
  title: `Gradients of the Network vol. 1`,
  written: `2018-05-19`,
  updated: `2018-05-19`,
  layoutType: `post`,
  contentType: "blog",
  path: `/gradvizv1/`,
  category: `Deep learning`,
  image: './poster.jpg',
  description: `What do filters in a convolutional neural network learn? How do we visualize a convolutional layer ? Can the Neural networks dream and if they do, do they dream of electric sheep ?`,
}

class Gradientvisualizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'top',
    };
  }

  handleModeChange = (e) => {
    const mode = e.target.value;
    this.setState({ mode });
  }

  render() {
    // console.log(this.props.data)
    const vgg_block1_conv1 = this.props.data.vgg_block1_conv1.childImageSharp.resolutions
    const vgg_block2_conv1 = this.props.data.vgg_block2_conv1.childImageSharp.resolutions
    const vgg_block3_conv1 = this.props.data.vgg_block3_conv1.childImageSharp.resolutions
    const vgg_block4_conv1 = this.props.data.vgg_block4_conv1.childImageSharp.resolutions
    const vgg_block5_conv1 = this.props.data.vgg_block5_conv1.childImageSharp.resolutions
    const electric_sheep = this.props.data.electric_sheep.childImageSharp.resolutions
    const { mode } = this.state;
    return (
      <Layout data={this.props.data} location={this.props.location}>
      <BlogPostChrome {...this.props.data.javascriptFrontmatter}>
        <h1>Visualizing ConvNet Filters</h1>
        <article>
          <p>
            In this post, we visualize the filters of CNN trained on the imagenet.
            The are different ways to visualize the network, but we are going to focus on two:
            1) visualize individual filters of a single layer
            2) visualize the output of the entire layer(s)
          </p>
          <p>
            But why do it?
            We think that by visualizing the filters at different layers we get a better grasp of what the filters have learned?
            What are the differences between the filters at various layers e.g how different is the filter at first layer from the one at last convolutional layer ?
            And, my personal favourite, it allows us to create some really fun <a href="/deepdream">psychedelic visuals</a> (generative art).
          </p>
        </article>
        <Divider />
        <h2>Filter visualization</h2>
        <p>
          During the training phase of the Neural network, the weights are learned/updated in order to bring the predictions closer to true labels.
          For filter visualization, we keep the filter fixed and modify the input image such that it maximizes the filter output.
          The libraries such as Keras make is very easy to implement such techniques.
          Let's break down the process.
          </p>
        <ol>
          <li>Start with a pretrained model, VGG16 trained on imagenet in our case</li>
          <li>Keep the convolutional layers from the model and discard the dense layers as we want to visualize the filters</li>
          <li>Choose a layer and filter to visualize and use its output as loss function</li>
          <li>Get the gradient of loss with respect to the model's input</li>
          <li>Change the model's input using gradient values to minimise the loss i.e bring the input closer to filter output</li>
        </ol>
        {/* This notebook shows how to do this in keras. */}
        <p>
          <Button type="primary" icon="code" size="large" href="https://colab.research.google.com/drive/1duHQpN4GlYZr6uPScoNgsIo4EeqgkZBl">Review the annotated code on colab</Button>          
        </p>
          <p>
          Let's take a look at the first 25 filters of all VGG16 conv layers. 
          Each layer builds upon the previous and detects increasingly complex structures.
          The initial layers detect colors and edges, the subsequent layers detect textures 
          and the top ones detect shapes such as eyes, nose or faces.
          </p>
        <Tabs defaultActiveKey="1" tabPosition={mode}>
          <TabPane tab="Block1 Conv1" key="1"><Img resolutions={vgg_block1_conv1} /></TabPane>
          <TabPane tab="Block2 Conv1" key="2"><Img resolutions={vgg_block2_conv1} /></TabPane>
          <TabPane tab="Block3 Conv1" key="3"><Img resolutions={vgg_block3_conv1} /></TabPane>
          <TabPane tab="Block4 Conv1" key="4"><Img resolutions={vgg_block4_conv1} /></TabPane>
          <TabPane tab="Block5 Conv1" key="5"><Img resolutions={vgg_block5_conv1} /></TabPane>
        </Tabs>
        <Divider />
        <h2>Layer visualization</h2>

        <p>
        This approach is known by a popular name <a href="https://ai.googleblog.com/2015/06/inceptionism-going-deeper-into-neural.html">DeepDream</a> that was developed by Mordvintsev et al from Google.
        It has two parts to it: 1) the approach to create the layer visualization 2) letting the network dream/hallucinate
        </p>
        <p>
        The layer visualization approach is similar to the filter visualization but here we use the output of the entire layer in our loss function and model's input is generally an image instead of noise image.
        Let's call it the layer visualizer function, it takes an image and layer name as input, runs gradient descend to minimise the loss function and returns the modified image. 
        In order to get the network to dream, we do the following:
        </p>
        <ol>
          <li>Load an image and pass it to the visualizer function along with the layer of choice</li>
          <li>Get back the resultant image, save a copy to disk</li>
          <li>Pass the resultant image to visualizer function</li>
          <li>Repeat the previous two steps over and over</li>
          <li>Stitch the generated images to create gif or movie</li>
        </ol>        
        <p>
        That's it. Well, the visualizer function does a few more things in order to improve the quality of resultant images.
        It scales down the image, performs gradient descend on it, upscale it by a certain factor, run gradient descend again and so on.
        This is done for a predefined number of times known as octaves. 
        It also adds a bit of gaussian blur to the image after every gradient descend to make the images look smooth. 
        </p>
        <p>
          Do androids dream of electric sheep ?
        </p>        
        <Img resolutions={electric_sheep} />

        The images shown <a href="/deepdream">here</a> were dreamt by the network.

        <Divider />
        <h2>Conclusion</h2>
        <p>
          We saw that optimising model's input in order to maximize the filter or layer output can provide us some information about what the filter or layer sees.
          We saw that the output/visuals generated by filter visualization technique did not show clear/sharp images.
          This is because it takes a lot more than gradient descend to get better results and it is covered in great detail by this excellent resource (see enemy of feature visualization).
          Deep dream on the other hand does create some excellent images and one can experiment with models trained on various datasets such as mscoco, pascal or any other.
        </p>
        <Divider />
        <Row>
                        {/* <Col span={4}>
          Additional Resources:
          </Col>
          <Col span={20}>
          </Col> */}
                        <Col span={4} className="references-header">
                            References:
          </Col>
                        <Col span={20} className="references-text">
                            <ol>
                                <li>
                                DeepDream  
                                &nbsp;[<a href="https://ai.googleblog.com/2015/07/deepdream-code-example-for-visualizing.html" target="_blank" rel="noopener noreferrer">website</a>,
                                &nbsp;<a href="https://github.com/google/deepdream/blob/master/dream.ipynb" target="_blank" rel="noopener noreferrer">notebook</a>]                                
                            </li>
                                <li>
                                Enemy of feature visualization  
                                &nbsp;[<a href="https://distill.pub/2017/feature-visualization/#enemy-of-feature-vis" target="_blank" rel="noopener noreferrer">website</a>]                                
                            </li>
                            </ol>
                        </Col>
                    </Row>        
      </BlogPostChrome>
      </Layout>
    )
  }
}

export default Gradientvisualizer

const styles = {}

styles.row = {
  display: `flex`,
  flexWrap: `wrap`,
  margin: `8px -4px 1rem`,
}

// We want to keep this component mostly about the code
//  so we write our explanation with markdown and manually pull it in here.
//  Within the config, we loop all of the markdown and createPages. However,
//  it will ignore any files appended with an _underscore. We can still manually
//  query for it here, and get the transformed html though because remark transforms
//  any markdown based node.
export const pageQuery = graphql`
    query gradvizqueryv1($slug: String!) {
      javascriptFrontmatter(fields: { slug: { eq: $slug } }) {
        ...JSBlogPost_data
      }
      vgg_block1_conv1: file(
        relativePath: {regex: "/vgg_block1_conv1.png/"}) {
        childImageSharp {
          resolutions(width: 800, height: 799) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
      vgg_block2_conv1: file(
        relativePath: {regex: "/vgg_block2_conv1.png/"}) {
        childImageSharp {
          resolutions(width: 800, height: 798) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
      vgg_block3_conv1: file(
        relativePath: {regex: "/vgg_block3_conv1.png/"}) {
        childImageSharp {
          resolutions(width: 800, height: 799) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
      vgg_block4_conv1: file(
        relativePath: {regex: "/vgg_block4_conv1.png/"}) {
        childImageSharp {
          resolutions(width: 800, height: 802) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
      vgg_block5_conv1: file(
        relativePath: {regex: "/vgg_block5_conv1.png/"}) {
        childImageSharp {
          resolutions(width: 800, height: 799) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
      electric_sheep: file(
        relativePath: {regex: "/electric_sheep.jpg/"}) {
        childImageSharp {
          resolutions(width: 800, height: 500) {
            ...GatsbyImageSharpResolutions
          }
        }
      }    
    }
  `
